import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import * as Q from "queries"
import styles from "./404.module.css"

export const NotFoundPage = () => {
  const file = Q.use404Image()

  return (
    <section className={styles.root}>
      <h1 className={styles.title}>404 или чего-то не нашлось</h1>

      <div className={styles.icon}>
        <Img
          objectFit="cover"
          objectPosition="50% 50%"
          alt="success"
          key="success"
          fluid={file.childImageSharp.fluid}
        />
      </div>

      <footer className={styles.footer}>
        <Link to="/" className={styles.link}>
          Вернуться на главную
        </Link>
      </footer>
    </section>
  )
}

export default NotFoundPage
