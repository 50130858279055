import { useStaticQuery, graphql } from "gatsby"

export const useColors = () => {
  const {
    allColor: { nodes },
  } = useStaticQuery(graphql`
    query AllColorQuery {
      allColor {
        nodes {
          color
          label
          price
          value
        }
      }
    }
  `)
  return nodes
}

export default useColors
