import { useStaticQuery, graphql } from "gatsby"

export const usePapers = () => {
  const {
    allPaper: { nodes },
    file,
  } = useStaticQuery(graphql`
    query AllPaperQuery {
      allPaper {
        nodes {
          color
          description
          gsm
          price
          title
          category
        }
      }
      file(relativePath: { eq: "my_paper.png" }) {
        childImageSharp {
          fluid(maxWidth: 475, maxHeight: 475) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return [nodes, file]
}

export default usePapers
