import { useStaticQuery, graphql } from "gatsby"

export default () =>
  useStaticQuery(graphql`
    query CalculatorInitialStageImagesQuery {
      allFile(filter: { sourceInstanceName: { eq: "images" } }) {
        edges {
          node {
            id
            relativePath
            childImageSharp {
              fluid(maxWidth: 308, maxHeight: 308) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)
