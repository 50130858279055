import { useStaticQuery, graphql } from "gatsby"

export const useSuccessImage = () => {
  const { file } = useStaticQuery(graphql`
    query SuccessImageQuery {
      file(relativePath: { eq: "mail.png" }) {
        childImageSharp {
          fluid(maxWidth: 475, maxHeight: 475) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return file
}

export default useSuccessImage
